import React from "react";
import { Box } from 'rebass';
import IndividualSquare from "./individual-square";

interface Props {
    squares: any;
    onClick: any;
}

const Board = ({ squares, onClick }: Props) => (
  <Box css={{display: 'grid', backgroundColor: 'primary', gridTemplateColumns: 'repeat(3, auto)', alignContent: 'center', justifyContent: 'center'}}>
    {squares.map((square: any, i: number) => (
      <IndividualSquare key={i} value={square} onClick={() => onClick(i)} />
    ))}
  </Box>
);

export default Board;
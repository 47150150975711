import React from "react";
import { Text, Box } from 'rebass';

interface Props {
    value: "X" | "O";
    onClick: any;
}

const IndividualSquare = ({ value, onClick }: Props) => {
  return (
    <Box variant="secondary" css={{ width: '100px', height: '100px', border: '1px solid', display: 'grid', alignContent: 'center', justifyContent: 'center'}} onClick={onClick}>
      <Text css={{ fontSize: '90px', color: 'primary'}}>{value}</Text>
    </Box>
  );
};

export default IndividualSquare;
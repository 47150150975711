import React from 'react';
import Layout from "../ui-components/layout";
import { Box } from "rebass";
import Game from '../components/tic-tac-toe/game';

const TicTacToe = () => (
    <Layout>
        <Box css={{marginTop: '50px'}}>
            <Game />
        </Box>
    </Layout>
);

export default TicTacToe;
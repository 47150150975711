import React, { useState } from "react";
import { Box, Button } from 'rebass';

import { calculateWinner } from './utils';
import Board from "./board";

import MainContainer from '../../ui-components/container/container';
import Heading from '../../ui-components/heading/heading';


const Game = () => {
  const [history, setHistory] = useState([Array(9).fill(null)]);
  const [stepNumber, setStepNumber] = useState(0);
  const [isXnext, setisXnext] = useState(true);
  const winner = calculateWinner(history[stepNumber]);

  const click = (i: number) => {
    const historyPoint = history.slice(0, stepNumber + 1);
    const current = historyPoint[stepNumber];
    const squares = [...current];
    // return if won or occupied
    if (winner || squares[i]) return;
    // select square
    squares[i] = isXnext ? "X" : "O";
    setHistory([...historyPoint, squares]);
    setStepNumber(historyPoint.length);
    console.log('isX', isXnext);
    setisXnext(!isXnext);
  };

  const seeMoves = (step: any) => {
    setStepNumber(step);
    setisXnext(step % 2 === 0);
  };

  const showAllMoves = () =>
    history.map((_step, move) => {
      return (
        <Button key={move} my={3} variant={`${move !== 0 ? 'buttonSecondary' : 'buttonPrimary'}`} flex="0 1 18%" mr={3} onClick={() => seeMoves(move)}>{move ? `Move #${move}` : "Start over"}</Button>
      );
    });

  return (
    <MainContainer>
        <Box flex="0 1 100%" my={3}>
            <Heading renderedAs="h1">Play a game</Heading>
        </Box>
        <Box flex="0 1 100%" my={3}>
            <Board squares={history[stepNumber]} onClick={click} />
        </Box>
        <Box flex="0 1 100%" display="flex" my={3} css={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Heading renderedAs="h3" variant="subheading">{winner ? ("And the winner is: " + winner) : (isXnext ? "Next up: X" : "Next up: O")}</Heading>
            <Box css={{marginTop: '40px'}} flex="0 1 100%">
                <Heading renderedAs="h2" variant="subheading">Historical Plays</Heading>
                <Box css={{ display: 'flex', flexWrap: 'wrap'}}>
                    {showAllMoves()}
                </Box>
            </Box>
        </Box>
    </MainContainer>
  );
};

export default Game;